import * as React from "react";
// import Helmet from "react-helmet";
// import Plantilla from "../../components/plantilla";
// import Layout from "../../components/layout";
// import { cartaFiniquito } from "../../utils/plantillas/atencion-cliente"
import { Grid, Button, Modal } from "@mui/material";
import Login from "./login";

const ModalLogin = ({ setSession, setOpenLogin, openLogin, onClose, onLogin = (message, variant)=>{}, loginView, canCloseModal = true}) => {

    // const [openLogin, setOpenLogin] = React.useState(false);
    const handleOpenLogin = () => setOpenLogin(true);
    const handleCloseLogin = (event, reason) => {
        // console.log(reason);
        if (reason !== 'backdropClick') {
            setOpenLogin(false)
            if(typeof(onClose) === "function") onClose();
            // document.getElementById("zsiq_float").style.display = "block";
        }
    };

    return (
        <React.Fragment>

            {/* <Layout session={session} setSession={setSession}>
                <Helmet>
                    <title>{props.title}</title>
                </Helmet> */}

            {/* <React.Fragment> */}
            <div >
                <Button id="btnModalLogin" style={{ display: "none" }} onClick={handleOpenLogin}></Button>

                <Modal className={"modal modalResponsiveBottom"}
                    //keepMounted
                    open={openLogin}
                    onClose={handleCloseLogin}
                    disableEscapeKeyDown={true}
                    disableScrollLock={false}
                    // slotProps={{backdrop: {backdropFilter: "blur(5px)"}}}
                >
                    <Grid className={"divContainerModalLogin"}>
                        <Grid style={{ width: "100%", height: "100%", display: "flex", alignItems: "center"}} className="divContainerModalLoginGrid">
                            {openLogin &&
                                <Login setSession={setSession} setOpenLogin={setOpenLogin} onLogin={onLogin} loginView={loginView} closeModal={handleCloseLogin} canCloseModal={canCloseModal}></Login>
                            }
                        </Grid>
                    </Grid>
                </Modal>
            </div>
            {/* 
                </React.Fragment>
            </Layout> */}

        </React.Fragment>
    );
};

// ModalLogin.defaultProps = {
//     title: "Carta Finiquito", //titulo del index
// }

export default ModalLogin;
