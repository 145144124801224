import { Grid, Button, Input, FormControl, InputLabel, Grow, Modal, Select, MenuItem } from '@mui/material';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CrearCuentaUsuarioExterno, UserLogin, saveUserData, saveUserIDs, validarEmail, validarTel } from '../hooks/login';

import '../styles/login.css'
import { navigate } from 'gatsby';
import { AlertaStack, Spinner } from './components';

import RecoveryPasswordActivateAccount from './recoveryPassword';
import { useForm } from "react-hook-form";

import ReCAPTCHA from "react-google-recaptcha";
import CloseButton from './close-button';
import PropTypes from 'prop-types';
import { usePageContext } from "../context";
import SheetGrid from './sheet-grid/sheet-grid';

let test = process.env.TESTING === "true";

export default function Login({ setSession, setOpenLogin, view, onLogin = (message, variant)=>{}, loginView = false, closeModal = () => {}, canCloseModal = true}) {
    const pageContext = usePageContext();
    const [loading, setLoading] = React.useState(false);
    const [loginVisible, setLoginVisible] = React.useState(false);
    const [registroVisible, setRegistroVisible] = React.useState(false);
    const [eyePass, setEyePass] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [messageLogin, setMessageLogin] = React.useState("");
    const [alertVariant, setAlertVariant] = React.useState("default");
    const [email, setEmail] = React.useState("");
    const [cliente, setCliente] = React.useState(null);
    const [newAlert, setNewAlert] = React.useState(0);
    const [accion, setAccion] = React.useState("");

    const { register, handleSubmit } = useForm();

    const [open, setOpen] = React.useState(false);

    let testUser;

    const changeToRegistro = () => {
        if (!view) {
            setLoginVisible(false);
            setRegistroVisible(true);
        } else {
            navigate("/registro");
        }
    };
    const changeToLogin = () => {
        if (!view) {
            setLoginVisible(true);
            setRegistroVisible(false);
        } else {
            navigate("/login");
        }
    }

    const mostrarPassword = () => (
        setEyePass(!eyePass)
    )

    const recaptcha = useRef();
    const onSubmit = async (data) => {
        if (process.env.NODE_ENV !== "development" && !test) {
            const captchaValue = recaptcha.current.getValue();
            if (!captchaValue) {
                alert("Favor de verificar reCAPTCHA");
                return;
            }
        }
        registro(data);
    }

    const submitLogin = (data) => {
        login(data);
    }
    
    function handleFetchError(error) {
        setMessage(error.message);
        setNewAlert((value) => value ? value + 1 : 1);
        setLoading(false);
        console.log(error);
    }

    useEffect(() => {
        if (loginView) {
            setLoginVisible(true);
            setRegistroVisible(false);
        } else {
            setLoginVisible(false);
            setRegistroVisible(true);
        }

        if (cliente !== undefined && cliente !== null && cliente.cliente !== 0) {
            window.sessionStorage.setItem("cliente", cliente.cliente)
            window.sessionStorage.setItem("email", email)
            setSession(true)
        }
        else if (cliente != null) {
        }
    }, [])

    const login = async (data) => {
        if (!test) {
            setLoading(true)
            setMessageLogin("Iniciando sesión");
            setEmail(data.email);
            LoginBD(data.email, data.password);
        } else {
            testEnvLogin().finally(() => {
                onLogin("Ha iniciado sesión correctamente", "success");           
                if (setOpenLogin) {
                    setOpenLogin(false)
                }
                setSession(true)
                disableLoginTimeOut();
            });            
        }
    }

    const LoginBD = async (email, pass) => {
        await UserLogin(email, pass).then((resp) => {
            if (resp.cliente > 0) {
                onLogin("Sesion iniciada", "success");
                setCliente(resp.cliente);
                setMessage("Sesion iniciada");
                disableLoginTimeOut();
                
                window.sessionStorage.setItem("cliente", resp.cliente);
                if (setOpenLogin) {
                    setOpenLogin(false);
                }
                setSession(true);                
                setLoading(false);
            }
            else {
                setMessage("Datos incorrectos");
                setAlertVariant("error");
                setNewAlert((value) => value + 1);
                setLoading(false);
            }
        }, (reason) => {
            console.log(reason);
        });
    }

    const registro = async (data) => {
        setLoading(true)
        setMessageLogin("Iniciando registro")

        if (!test) {
            //Validando Teléfono
            try {
                const resp = await validarTel(data.lada + data.telefono);
                if (resp.Codigo !== 0) {
                    throw new Error(resp.Mensaje);
                }
            } catch (error) {
                handleFetchError(error);
                return; // Salir de la función si hay un error
            }

            // Proceso de Registro
            setMessage("Validando Correo Electrónico");

            await validarEmail(data.correo).then(async (resp) => {
                if (resp.Codigo === 0) {
                    setMessage("Validando Correo Electrónico");

                    await CrearCuentaUsuarioExterno(data).then((resp) => {
                        if (resp.Code === 200) {
                            sessionStorage.setItem("nombre", data.nombre)
                            sessionStorage.setItem("RegistroAlert", "true");
                            onLogin("Registro exitoso, se enviaron sus credenciales a su correo electrónico.", "success");

                            if (setOpenLogin) {
                                setOpenLogin(false)
                            }
                            setSession(true)
                            disableLoginTimeOut();

                        } else if (resp.Code === 400) {
                            throw new Error("¡Ya estás registrado! Por favor, active su cuenta para iniciar sesion");
                        } else if (resp.Code === 401) {
                            throw new Error("La cuenta que esta intentando registrar ya existe, si aún no recibe sus credenciales en su correo electrónico comuníquese con Atención a Clientes.");
                        } else {
                            throw new Error("Ocurrio un error con el registro, contacte a Servicio al Cliente.");
                        }
                    }).catch((reason) => {
                        handleFetchError(reason);
                    });
                } else {
                    throw new Error(resp.Mensaje);
                }
            }).catch((error) => {
                handleFetchError(error);
            });

        } else {
            testEnvLogin().finally(() => {
                onLogin("Registro exitoso, se enviaron sus credenciales a su correo electrónico.", "success");
                if (setOpenLogin) {
                    setOpenLogin(false)
                }                
                setSession(true);
                disableLoginTimeOut();
            });
        }
    }

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false)
        }
    };

    const MobileActivateAccount = () => (
        <Grid id="mobileActivateAccount" className={"divRegistroLabels"} style={{background: "#e6ebf3", padding: 15, borderRadius: 5}}>
            <Button id='iniciarSesionButtonLoginForm' variant="text" onClick={() => { setOpen(true); setAccion("activate") }} type='button' style={{ padding: 0 }}><p className={"color-primary"} style={{ margin: 0 }}><span style={{color: "#4e4e4e"}}>¿Ya eres cliente?</span> Activa tu cuenta</p></Button>
        </Grid>
    );

    const ViewLogin = () => (
        <form onSubmit={handleSubmit(submitLogin)}>
                <Grid>
                    <p className={"titleLogin"}> Iniciar sesión
                    </p>
                </Grid>
                <div>

                    <Grid style={{ marginBottom: "20px" }} >
                        <FormControl variant="standard" style={{ width: "100%" }}>
                            <InputLabel style={{ marginTop: "10px", fontFamily: "Lato", color: "var(--color-primary)" }} >Usuario<span style={{ color: "red" }}>*</span></InputLabel>

                            <Input {...register("email")} autoCapitalize='off' autoComplete='off' autoCorrect='off' margin='none' name="email" spellCheck={false} type={"email"} required={!test}
                                inputProps={{ defaultValue: "", maxLength: 45, minLength: 10, pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$", inputMode: "text", title: "Por favor ingrese su correo electrónico." }}>
                                {/* onChange={changeUser} error={errorUser} */}
                            </Input>

                        </FormControl>

                    </Grid>
                    <Grid>
                        <FormControl style={{ width: "100%" }} >
                            <InputLabel variant="standard" style={{ marginTop: "10px", fontFamily: "Lato", color: "var(--color-primary)" }} >Contraseña<span style={{ color: "red" }}>*</span></InputLabel>
                            <Input vocab='' {...register("password")} inputProps={{ defaultValue: "", maxLength: 50, minLength: 1, inputMode: "text", title: "Por favor ingrese su contraseña." }} required={!test} name="password" placeholder="" spellCheck={false} type={eyePass ? "text" : "password"}>
                            </Input>
                            <button type='button' className={"visibilityIcon"} onClick={mostrarPassword}>
                                {eyePass ?
                                    <VisibilityOffIcon color='disabled'></VisibilityOffIcon>
                                    :
                                    <VisibilityIcon color='primary'></VisibilityIcon>
                                }
                            </button>
                        </FormControl>
                        <Grid style={{ textAlign: "right" }}>
                            <button type="button" style={{fontSize: 16, fontFamily: "Lato", background: "none", border: "none", paddingInline: 0}} className='recoverySpan' onClick={() => { setOpen(true); setAccion("password") }} >Recuperar Contraseña
                            </button>
                        </Grid>
                    </Grid>
                </div>
                <div>

                    <Grid className={"divBtn"}>
                        <Button className="btnLogin bounceButton" id="button-login" type="submit" >Acceder al sitio</Button>
                    </Grid>
                    <Grid className={"divRegistroLabels"}>
                        {/* <p>¿No estás registrado?</p> */}
                        <Button onClick={changeToRegistro} style={{ padding: 0 }}><p className={"color-primary"} style={{ margin: 0 }}><span style={{color: "#999"}}>¿No estás registrado?</span> Regístrate Aquí!</p></Button>
                    </Grid>
                    {/* {mobileActivateAcount()} */}
                </div>
            </form>
    )

    const ViewRegistro = () => (
        <form id='registroForm' onSubmit={handleSubmit(onSubmit)} >
            <Grid>
                <p className={"titleLogin"}> Regístrate</p>
            </Grid>
            {/* <Grid>
                <p className={"titleLogin registerLogin dangerFlashAnimated"} style={{ fontSize: 17 }}>Regístrate para recibir hasta <br/> un 50% de descuento online</p>
            </Grid> */}
            <Grid container className='formMarginBottom' spacing={2}>
                <Grid item xs={6} md={12}>
                    <FormControl variant="standard" style={{ width: "100%" }}>
                        <InputLabel style={{ marginTop: "10px", fontFamily: "Lato, sans-serif", color: "var(--color-primary)" }} >Nombre<span style={{ color: "red" }}>*</span></InputLabel>
                        <Input  {...register("nombre", { required: !test })} autoCapitalize='off' autoComplete='off' autoCorrect='off' margin='none' name="nombre" spellCheck={false} type={"text"} required={!test}
                            inputProps={{ maxLength: 45, minLength: 2, inputMode: "text", pattern: "[a-zA-ZáéíóúÁÉÍÓÚñÑ\\s]+", title: "Por favor ingrese su nombre. No ingrese números ni caracteres especiales."}}>
                        </Input>
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={12}>
                    <FormControl variant="standard" style={{ width: "100%" }}>
                        <InputLabel style={{ marginTop: "10px", fontFamily: "Lato, sans-serif", color: "var(--color-primary)" }} >Apellido Paterno<span style={{ color: "red" }}>*</span></InputLabel>
                        <Input  {...register("apellidoPaterno", { required: !test })} autoCapitalize='off' autoComplete='off' autoCorrect='off' margin='none' name="apellidoPaterno" spellCheck={false} type={"text"} required={!test}
                            inputProps={{ maxLength: 45, minLength: 3, inputMode: "text", pattern: "[a-zA-ZáéíóúÁÉÍÓÚñÑ\\s]+", title: "Por favor ingrese su apellido paterno. No ingrese números ni caracteres especiales."}}>
                        </Input>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid className='formMarginBottom'>
                <FormControl variant="standard" style={{ width: "80px", height: "56px" }}>
                    <InputLabel style={{ marginTop: "10px", fontFamily: "Lato, sans-serif", color: "var(--color-primary)" }} >Lada<span style={{ color: "red" }}>*</span></InputLabel>

                    <Select variant="standard" style={{
                        height: "100%",
                        borderBottom: "1px solid rgba(0, 72, 170, 0.5)"
                        }}
                        {...register("lada", { required: !test })}
                        autoCapitalize='off'
                        autoComplete='off'
                        autoCorrect='off'
                        margin='none'
                        name="lada"
                        spellCheck={false}
                        required={!test}
                        inputProps={{ defaultValue: 52 }}                        
                        renderValue={(value) => {
                            return (
                                <Grid item style={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
                                    {value === 52 &&
                                        <img style={{ margin: "auto", maxHeight: "15px", marginLeft: "0px" }} src='/static-files/flag-mexico.png' alt="Mexico Flag"></img>}
                                    {value === 1 &&
                                        <img style={{ margin: "auto", maxHeight: "15px", marginLeft: "0px" }} src='/static-files/flag-eua.png' alt="USA Flag"></img>}
                                    +{value}
                                </Grid>
                            );
                        }}
                    >
                        <MenuItem value={52} style={{ display: "flex", justifyContent: "space-between" }}>+52</MenuItem>
                        <MenuItem value={1}>+1</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ width: "calc(100% - 80px)" }}>
                    <InputLabel style={{ marginTop: "10px", fontFamily: "Lato, sans-serif", color: "var(--color-primary)" }} >Teléfono<span style={{ color: "red" }}>*</span></InputLabel>
                    <Input 
                        {...register("telefono", { required: !test })} 
                        autoCapitalize='off'
                        autoComplete='off'
                        autoCorrect='off'
                        margin='none'
                        name="telefono"
                        spellCheck={false}
                        type={"tel"}
                        required={!test}
                        inputProps={{ maxLength: 10, minLength: 10, pattern: "^[0-9]{10}", title: "El número de teléfono debe incluir únicamente números y debe tener 10 dígitos", inputMode: "tel" }}>
                    </Input>
                </FormControl>
            </Grid>

            <Grid className='formMarginBottom'>
                <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel style={{ marginTop: "10px", fontFamily: "Lato, sans-serif", color: "var(--color-primary)" }} >Correo electrónico<span style={{ color: "red" }}>*</span></InputLabel>
                    <Input  {...register("correo", { required: !test })} autoCapitalize='off' autoComplete='off' autoCorrect='off' margin='none' name="correo" spellCheck={false} type={"email"} required={!test}
                        inputProps={{ maxLength: 75, minLength: 10, pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$", inputMode: "email", title: "Por favor ingrese su correo electrónico." }}>
                    </Input>
                </FormControl>
            </Grid>
            <div>
                {(process.env.NODE_ENV !== "development" && !test) &&
                    <div style={{display: "flex", justifyContent: "center", marginTop: 8}}>
                        <ReCAPTCHA ref={recaptcha} sitekey={"6LcKnFopAAAAAFmbtebFHc86PsyDocCSXp07o8oL"} />
                    </div>
                }
                <Grid className={"divBtn"}>
                    <Button className="btnLogin bounceButton" type="submit" value={"Registro"} id='button-login'>Accede al sitio y obtén <br/> 50% de descuento</Button>
                </Grid>
                <Grid className={"divRegistroLabels"}>
                    {/* <p >¿Ya está registrado?</p> */}
                    <Button id='iniciarSesionButtonLoginForm' variant="text" onClick={changeToLogin} type='button' style={{ padding: 0}}><p className={"color-primary"} style={{ margin: 0 }}><span style={{color: "#999"}}>¿Ya estás registrado?</span> Iniciar sesión</p></Button>
                </Grid>
                {/* {mobileActivateAcount()} */}
            </div>
        </form>
    )

    function disableLoginTimeOut() {
        pageContext?.setCanCloseModal(true);
        localStorage.removeItem("notLoggedTimeOut");
    }

    /**
     * Esta función está pensada para usarse de forma escalable en el código si se piensa utilizar la app en modo de test
     * y que se guarden los datos aquí
     */
    async function testEnvLogin() {
        let date = new Date();
        let dateString = date.getDay().toString() + date.getHours().toString() + date.getMinutes().toString() + date.getSeconds().toString();
        return fetch(process.env.DATA_API_URL + "/test-user.json?v=" + dateString, {
            method: "GET"
        })
        .then(response => response.json())
        .then((response) => {
            testUser = response;
            saveUserData(testUser);
            saveUserIDs(testUser.DatosCliente.IdUsuario, testUser.DatosCliente.IdCte);
        });
    }

    return (
        <React.Fragment>
            {open &&

                <Modal className={"modal"}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    <RecoveryPasswordActivateAccount tabIndex={-1} setOpen={setOpen} accion={accion}></RecoveryPasswordActivateAccount>
                </Modal>
            }

            {newAlert > 0 &&
                <AlertaStack message={message} newAlert={newAlert} variant={alertVariant}></AlertaStack>
            }
            
            {loading && !setOpenLogin &&
                <Spinner mensaje={messageLogin} spinnerType={"loginSpinner"} sizePage={true} classSizePage={"loginPageContainer"} backgroundColor={"#0000001a"} />
            }

            <Grow in={true} timeout={{ enter: 500 }}>
                <Grid container className={"loginContainer "}>
                    {canCloseModal &&
                        <CloseButton onClick={closeModal} />
                    }

                    {loading && setOpenLogin &&
                        <Spinner mensaje={messageLogin} spinnerType={"loginSpinner"} borderRaius={"15px"} widthPage={true} sizePage={true} classSizePage={"loginContainer"} backgroundColor={"#0000001a"} />
                    }
                    <Grid item lg={6} md={6} sm={12} xs={12} className={"divLogoContainer"} >
                        <Grid className={"divLogo"} >
                            <Grid className="auth-logo-component" style={{textAlign: "center", width: "100%"}}>
                                <img alt="logo grupo Aries" className="auth-logo-compra" src={"/static-files/logoariesonline.svg"} />
                            </Grid>
                        </Grid>
                        <p style={{ color: "white" }}>
                            {/* ¿Ya eres cliente? */}
                            {/*
                            */}<button style={{fontSize: "inherit", letterSpacing: "inherit", fontFamily: "Lato, Lato, sans-serif", background: "none", border: "none", fontWeight: "400", color: "#FFFFFF"}} className='recoverySpan' onClick={() => { setOpen(true); setAccion("activate") }}> 
                             <span>¿Ya eres cliente?</span> Activa tu cuenta
                            </button>
                        </p>
                    </Grid>
                    <SheetGrid item lg={6} md={6} sm={12} xs={12} className={"divLogin"} style={{ padding: "25px", paddingTop: 50, minHeight: "540px" }}>
                        {canCloseModal &&
                            <CloseButton onClick={closeModal} />
                        }
                        <Grid className={"w-100"} style={{ maxWidth: "400px", height: "100%" }} >
                            {/* Login */}

                            {loginVisible === true &&
                                <ViewLogin />
                            }
                            {registroVisible === true &&
                                <ViewRegistro />
                            }
                            <MobileActivateAccount />
                        </Grid>
                    </SheetGrid>
                </Grid>
            </Grow>
            {/* </Modal> */}
        </React.Fragment>
    )
}

Login.propTypes = {
    setSession: PropTypes.func,
    setOpenLogin: PropTypes.func,
    view: PropTypes.any,
    onLogin: PropTypes.func,
    loginView: PropTypes.bool,
    closeModal: PropTypes.func,
    canCloseModal: PropTypes.bool
}
