import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

export default function CookiesAlert() {
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const acceptCookies = () => {
        // Establecer la cookie de aceptación
        document.cookie = 'cookiesAccepted=1; max-age=31536000'; // La cookie expirará en 1 año
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button className='btnAceptCookies' size="small" onClick={acceptCookies}>
                Aceptar
            </Button>
        </React.Fragment>
    );

    const message = (
        <React.Fragment>
            <p className='cookiesText'>Utilizamos cookies para recopilar información sobre cómo navegas en Puntazul Residencial. Las cookies mejoran tu experiencia en nuestro sitio. Si deseas más información sobre cómo utilizamos las cookies, visita nuestra <a style={{ fontSize: "inherit" }} target='_blank' href="/politicas-cookies">Política de Cookies</a>.

            </p>
        </React.Fragment>
    )

    React.useEffect(() => {
        // Comprobar si la cookie de aceptación está presente
        const cookiesAccepted = document.cookie.split(';').some(cookie => cookie.trim().startsWith('cookiesAccepted=1'));

        // Establecer el estado según el resultado
        setOpen(!cookiesAccepted);

    }, []);

    return (
        <div>
            <Snackbar className='SnackbarCookies'
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleClose}
                message={message}
                action={action}
            />
        </div>
    );
}