const urlPEL = {
    url: process.env.API_URL + "/CuentaPEL/rest/",
    userName: process.env.API_USER_CUENTA_PEL,
    password: process.env.API_PASS_CUENTA_PEL,
}

const urlECommerce = {
    url: process.env.API_URL + "/ECommerce/rest/",
    userName: process.env.API_ECOMMERCE_USER_NAME,
    password: process.env.API_ECOMMERCE_PASSWORD,
}

/**
 * Iniciar sesión con usuario (correo) y contraseña
 * @param {string} user 
 * @param {string} password 
 * @returns reponse Código y Mensaje de respuesta del Server
 */
export async function UserLogin(user, password) {
    let response;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userName: urlPEL.userName,
            password: urlPEL.password,
            usuarioExterno: user.toUpperCase().trim(),
            passCuenta: password            
        })
    };

    await fetch(urlPEL.url + 'AccesoCuentaECommerceAsync', requestOptions)
    .then(response => response.json())
    .then(async (data) => {
        if (data.IdUsuarioExterno) {
            saveUserIDs(data.IdUsuarioExterno, data.cliente);
            await ObtenerDatosUsuario(data.cliente)
        }
        response = data;
    }).catch((error) => {
        console.log("Error al iniciar sesion:", error );
    });

    return response;
}

/**
 * Validar un email si es existente
 * @param {*} email 
 * @returns reponse Código y Mensaje de respuesta del Server
 */
export async function validarEmail(email) {
    let response;
    let url = `${process.env.API_URL}/Validacion/rest/ValidarEmail?userName=${process.env.API_USER_VALIDAR_EMAIL}&password=${process.env.API_PASS_VALIDAR_EMAIL}&email=${email}`;
    
    let data = {Codigo: 0}
    return data;
    await fetch(url)
    .then(response => response.json())
    .then(data => {
        response = data;
        return data;
    }).catch((error) => {
        console.log("Error al validar email:", error);
    });

    return response;
}

/**
 * Validar si un teléfono es existente
 * @param {*} teléfono 
 * @returns reponse Código y Mensaje de respuesta del Server
 */
export async function validarTel(tel) {
    let response;
    let url = `${process.env.API_URL}/Validacion/rest/ValidarTelefono?userName=${process.env.API_USER_VALIDAR_EMAIL}&password=${process.env.API_PASS_VALIDAR_EMAIL}&telefono=${tel}`;
    
    // let data = {Codigo: 100, Mensaje: "Teléfono Inválido"}
    // return data;
    await fetch(url)
    .then(response => response.json())
    .then(data => {
        response = data;
        return data;
    }).catch((error) => {
        console.log("Error al validar teléfono:", error);
    });

    return response;
}

/**
 * Obtener datos del usuario usando su IdCte (id de cliente)
 * @param {*} idClient 
 * @returns response datos de usuario
 */
export async function ObtenerDatosUsuario(idClient) {
    let response;
    let url = `${urlECommerce.url}/ObtenerDatosUsuario?userName=${urlECommerce.userName}&password=${urlECommerce.password}&idCte=${idClient}`

    await fetch(url)
    .then(response => response.json())
    .then(data => {
        saveUserData(data);
        response = data;
        return data;
    }).catch(error => {
        console.log("Error al obtener datos del usuario:", error);
    });

    return response;
}

/**
 * Enviar token de recuperación de contraseña por correo electrónico utilizando el correo electrónico
 * O Enviar token de activación de cuenta utilizando los datos de cuenta del usuario ingresados
 * @param {*} data 
 * @param {string} accion 
 * @returns reponse Código y Mensaje de respuesta del Server
 */
export async function EnviarToken(data, accion) {
    let response;
    let urlServicio;
    let body;

    if (accion === "password") {
        urlServicio = "EnvioCodigoRecuperacionAsync";
        body = JSON.stringify({
            userName: urlPEL.userName,
            password: urlPEL.password,
            usuarioExterno: data.correo.toUpperCase().trim(),
            medioOnline: "PEL",
            idioma: "ES",
            acronimo: "VM"
        })
    } else {
        urlServicio = "EnvioCodigoActivacionAsync";

        let dateArray = data.fechaNacimiento.split("-");
        let newDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;

        body = JSON.stringify({
            userName: urlPEL.userName,
            password: urlPEL.password,
            expediente: data.expediente.trim(),
            nombreCliente: data.nombreCompleto.toUpperCase(),
            fechaNacimiento: newDate.trim(),
            correo: data.correo.toUpperCase(),
            telefono:  `${data.lada}${data.telefono.trim()}`,
            medioOnline: "PEL",
            idioma: "ES",
            acronimo: "VM"
        })
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    await fetch(urlPEL.url + urlServicio, requestOptions)
        .then(response => response.json())
        .then(async (data) => {
            response = data;
        }
        ).catch((error) => {
            console.log(`Error al enviar token de ${accion === "password" ? "contraseña:": "activacion de cuenta:"} `, error );
        });

    return response;
}

/**
 * Restablecer una cuenta de usuario por password olvidado utilizando el token recibido por correo electrónico
 * @param {*} email 
 * @param {*} token 
 * @returns reponse Código y Mensaje de respuesta del Server
 */
export async function RestablecerPassword(email, token) {
    let response;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userName: urlPEL.userName,
            password: urlPEL.password,
            usuarioExterno: email.toUpperCase().trim(),
            codigo: token,
            acronimo: "VM"
        })
    };

    await fetch(urlPEL.url + 'RestablecerPasswordAsync', requestOptions)
    .then(response => response.json())
    .then(async (data) => {

        response = data;
    }).catch((error) => {
        console.log("Error al restablecer contraseña:", error );
    });

    return response;
}

/**
 * Activar una nueva cuenta de usuario
 * @param {*} email 
 * @param {*} token 
 * @returns reponse Código y Mensaje de respuesta del Server
 */
export async function ActivarCuenta(email, token) {
    let response;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userName: urlPEL.userName,
            password: urlPEL.password,
            correo: email.toUpperCase().trim(),
            codigo: token.trim(),
            idioma: "ES",
            acronimo: "VM"
        })
    };
    await fetch(urlPEL.url + 'ActivarCuentaAsync', requestOptions)
    .then(response => response.json())
    .then(async (data) => {
        response = data;
    }).catch((error) => {
        console.log("Error al activar cuenta:", error );
    });

    return response;
}

/**
 * Registro de un nuevo usuario/prospecto en el sistema.
 * @param {*} data objeto del formulario con datos del nuevo usuario
 * @returns objeto con los datos del usuario registrado
 */
export async function CrearCuentaUsuarioExterno(data) {
    let response;
    let url = `${process.env.API_URL}/CuentaPEL/rest/RegistrarUsuarioCEL`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userName: urlPEL.userName,
            password: urlPEL.password,
            request: {
                acronimo: "PA",
                OrigenSolicitud: "WEB_PUNTAZUL",
                Nombre: data.nombre.trim(),
                ApellidoPaterno: data.apellidoPaterno.trim(),
                TelCelular: `${data.lada}${data.telefono.trim()}`,
                Email1: data.correo.toUpperCase().trim(),
            },
        })
    };

    await fetch(url, requestOptions)
        .then(response => response.json())
        .then(async (res) => {
            if (res.Code === 200) {
                response = res;
    
                saveUserIDs(res.Data.IdUsuarioExterno, res.Data.IdProspecto);
                saveUserData({
                    UserName: data.correo.toUpperCase().trim(),
                    DatosCliente: {
                        TelefonoMovil: `${data.lada}${data.telefono.trim()}`,
                        IdCte: res.Data.IdProspecto,
                        Nombre: data.nombre.trim(),
                        ApellidoPaterno: data.apellidoPaterno.trim()
                    }
                });
            } else {
                console.log(res)
                response = res;
            }
        }).catch((error) => {
            console.log("Error al crear cuenta de usuario:", error )
        });

    return response;
}

/**
 * Guardar en sessionStorage la información del usuario logeado/registrado 
 * para el uso de sus datos en sesión y para el apartado
 * @param {*} datosCliente
 */
export function saveUserData(datosCliente) {
    window.sessionStorage.setItem("datosCliente", JSON.stringify(datosCliente));
}

/**
 * Guardar en sessionStorage los ID del usuario logeado/registrado IdCliente/prospecto e idUsuarioExterno
 * @param {string} IdUsuarioExterno 
 * @param {string} IdCte 
 */
export function saveUserIDs(IdUsuarioExterno, IdCte) {
    window.sessionStorage.setItem("cliente", IdCte);
    window.sessionStorage.setItem("IdUsuarioExterno", IdUsuarioExterno);
}

