import React, { Fragment, useEffect, useState } from "react";
import { Button, FormControl, Grid, Input, InputLabel, MenuItem, Select } from "@mui/material";
import '../styles/recoveryActivateToken.css'
import { EnviarToken, RestablecerPassword, ActivarCuenta } from "../hooks/login";
import { AlertaStack, Spinner } from "./components";
import moment from "moment";
import { useForm } from "react-hook-form";
import CloseButton from "./close-button";


const EmailAnimation = () => (
    <Grid className="containerAnimationEmail">
        <svg className="mail" height="120" width="200">
            <polyline points="20,18 180,18 180,100 20,100 20,20 100,75 178,20" />
        </svg>
        <svg className="planeWhite" height="120" width="200">
            <polyline points="160,20 40,60 150,80 160,20 88,68 88,95 110,73" />
        </svg>
        <svg className="plane" height="120" width="200">
            <polyline points="160,20 40,60 150,80 160,20 88,68 88,95 110,73" />
        </svg>
    </Grid>
)

const RecoveryPasswordActivateAccount = React.forwardRef(({ open, setOpen, accion }, ref) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState("correo@gmail.com");
    // const [token, setToken] = useState("");
    const [state, setState] = useState("send");
    const [newAlert, setNewAlert] = useState(0);
    const [message, setMessage] = useState("");
    const [mensajeSpinner, setMensajeSpinner] = useState("");
    const { register, handleSubmit } = useForm();
    let test = process.env.TESTING === "TRUE";
    // const {  } = useForm()

    // const [data, setData] = useState({ expediente: "", nombreCompleto: "", fechaNacimiento: "", correo: "", telefono: "" })
    const [minDate, setMinDate] = useState("")
    const [fechaNac, setFechaNac] = useState("")

    useEffect(() => {

        let date = moment()
        // let month = date.month();
        // let day = date.date();

        // let dateText = `${date.year()}-${date.month().toString().padStart(2, "0")}-${date.date().toString().padStart(2, "0")}`
        let mindate = `${date.year() - 18}-${(date.month() + 1).toString().padStart(2, "0")}-${date.date().toString().padStart(2, "0")}`
        // console.log(date);
        // console.log(data.fechaNacimiento);
        // setData({ ...data, fechaNacimiento: mindate })
        setMinDate(mindate)
        setFechaNac(mindate)
    }, [])

    const onSubmit = (data) => {

        sendCode(data)

        // console.log(data);
    }
    const onSubmitCode = (data) => {

        EnviarCredenciales(data)

        // console.log(data);
    }

    // const changeUser = (event) => {
    //     // debugger
    //     let val = event.target.value;
    //     let valid = RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$").test(val);
    //     let valido = event.target.validity.valid;
    //     setUser(val);
    //     if (!valid || !valido) {
    //         setErrorUser(true);
    //     } else {
    //         setErrorUser(false);
    //     }

    // }
    // const changeDate = (event) => {
    //     // debugger
    //     // let val = event.target.value;
    //     // let valid = RegExp("^[a-zA-Z0-9.!#$%&amp;'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$").test(val);
    //     let valido = event.target.validity.valid;
    //     let fecha = moment(data.fechaNacimiento)
    //     let años = moment().diff(fecha, 'years')
    //     if (años < 18) {
    //         setErrorDate(true);
    //     }
    //     else {

    //         setErrorDate(false);
    //     }
    //     console.log(valido);
    //     console.log(años);
    //     // setUser(val);
    //     // if (!valid || !valido) {
    //     //     setErrorUser(true);
    //     // } else {
    //     //     setErrorUser(false);
    //     // }

    // }



    const sendCode = async (data) => {
        // setState("code")
        // debugger
        // console.log(data)
        // let data
        // if (accion === "password") {
        //     data = user

        // }
        // else {
        //     data = register;
        // }

        // if ((user !== "" && !errorUser && accion === "password") || (accion === "activate") ||) {

        setMensajeSpinner("Verificando información")
        // if (accion === "password") {
        if (!test) {
            setLoading(true)


            await EnviarToken(data, accion).then((resp) => {
                if (resp.Codigo === 0) {
                    setUser(data.correo);
                    setState("code")

                }
                setMessage(resp.Mensaje)
                setNewAlert((value) => value + 1)
                setLoading(false)

            }).catch((error) => {

                setMessage(error)
                setNewAlert((value) => value + 1)
                // console.log(error)
                setLoading(false)

            });
        } else {
            setState("code")
        }
        // }
        // else {
        //     setMessage("Favor de ingresar un correo electrónico válido.")
        //     setNewAlert((value) => value + 1)

        // }
    }


    const EnviarCredenciales = async (data) => {
        // console.log(token)

        // setState("success")
        if (data.token.length === 6 && !test) {
            setLoading(true)
            setMensajeSpinner("Verificando token")

            if (accion === "password") {
                await RestablecerPassword(data.correo, data.token).then((resp) => {

                    if (resp.Codigo === 0) {
                        setState("success")
                    }
                    setMessage(resp.Mensaje)

                    setNewAlert((value) => value + 1)
                    setLoading(false)


                }).catch((error) => {
                    setMessage(error);
                    setNewAlert((value) => value + 1)
                    setLoading(false)
                    // console.log(error)

                });
            } else {
                await ActivarCuenta(data.correo, data.token).then((resp) => {

                    if (resp.Codigo === 0) {
                        setState("success")
                    }
                    setMessage(resp.Mensaje)

                    setNewAlert((value) => value + 1)
                    setLoading(false)


                }).catch((error) => {
                    setMessage(error);
                    setNewAlert((value) => value + 1)
                    setLoading(false)
                    // console.log(error)

                });
            }
        } else if (test) {
            setState("success")
        }
        else {
            setMessage("Favor de ingresar un token válido.");
            setNewAlert((value) => value + 1)

        }
    }
    // const EndiarCredenciales = async () => {
    //     console.log(token)

    //     // setState("success")
    //     if (token.length === 6) {
    //         setLoading(true)

    //         await RestablecerPassword(user, token).then((resp) => {
    //             debugger
    //             if (resp.Codigo === 0) {
    //                 setState("success")
    //             }
    //             setMessage(resp.Mensaje)

    //             setNewAlert((value) => value + 1)
    //             setLoading(false)


    //         }).catch((error) => {
    //             setMessage(error);
    //             setNewAlert((value) => value + 1)
    //             setLoading(false)
    //             console.log(error)

    //         });
    //     }
    //     else {
    //         setMessage("Favor de ingresar un token válido.");
    //         setNewAlert((value) => value + 1)

    //     }
    // }




    return (
        <Fragment>

            {newAlert > 0 &&
                <AlertaStack message={message} newAlert={newAlert}></AlertaStack>
            }
            <Grid className="modalContainerRecovery" innerRef={ref}>
                {loading &&
                    <Spinner mensaje={mensajeSpinner} sizePage={true} widthPage={true} borderRaius={"15px"} classSizePage={"modalContainerRecovery"} ></Spinner>
                }

                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", padding: "20px 20px 0px", position: "relative" }} >
                    <CloseButton onClick={() => { setOpen(false) }} />
                    <h2 style={{ marginBlock: "0px", color: "var(--aries)", fontSize: "30px" }}>{accion === "password" ? "Recuperar Contraseña" : "Activar Cuenta"}</h2>
                </Grid>
                {/* Titulo: Recuperar Contraseña
                texto: */}

                <Grid container className="containerRecovery" >
                    {state === "send" &&
                        <Fragment>

                            {accion === "password" &&
                                <form ref={ref} onSubmit={handleSubmit(onSubmit)} >
                                    <p style={{ fontWeight: "400" }}>
                                        Estimado inversionista, proporcione su correo electrónico registrado para apoyarle en el proceso de recuperación de contraseña.
                                    </p>
                                    <Grid style={{ marginBottom: "20px" }} >
                                        <FormControl style={{ width: "100%" }}>
                                            <InputLabel variant="standard" style={{ marginTop: "10px", fontFamily: "Lato", color: "var(--color-primary)" }} >Correo Electrónico<span style={{ color: "red" }}>*</span></InputLabel>

                                            <Input {...register("correo", { required: !test })} autoCapitalize='off' autoComplete='off' autoCorrect='off' margin='none' name="correo" spellCheck={false} type={"email"} required={!test}
                                                inputProps={{ maxLength: 60, minLength: 10, inputMode: "email", title: "Por favor ingrese su correo electrónico." }}>

                                            </Input>

                                        </FormControl>
                                    </Grid>
                                    <EmailAnimation />

                                    <Button className="btnLogin" style={{ alignSelf: "center" }} type="submit">
                                        Enviar
                                    </Button>


                                    {/* <Button className="btnLogin" style={{ alignSelf: "center", marginTop: "25px" }}value={"Enviar"} >Enviar </Button> */}
                                </form>
                            }
                            {accion === "activate" &&
                                <form ref={ref} onSubmit={handleSubmit(onSubmit)} >
                                    <Grid style={{ marginBottom: "20px" }} >
                                        <FormControl style={{ width: "100%" }}>
                                            <InputLabel variant="standard" style={{ marginTop: "10px", fontFamily: "Lato", color: "var(--color-primary)" }} >Expediente<span style={{ color: "red" }}>*</span></InputLabel>
                                            {/* <Input value={data.expediente} autoCapitalize='off' helperText="help" autoComplete='off' autoCorrect='off' margin='none' name="expediente" spellCheck={false} type={"text"} required={!test} onChange={(e) => { setData({ ...data, expediente: e.target.value }) }}
                                            inputProps={{ maxLength: 45, minLength: 10 }}>
                                        </Input> */}
                                            <Input {...register("expediente", { required: !test })} required={!test} inputProps={{ maxLength: 15, minLength: 6, inputMode: "text" }} title="Por favor ingrese su expediente." type="text" name={"expediente"}></Input>
                                            {/* {errors.expediente &&
                                                <span className="errorSpan">Campo requerido</span>} */}
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{ marginBottom: "20px" }} >
                                        <FormControl style={{ width: "100%" }}>
                                            <InputLabel variant="standard" style={{ marginTop: "10px", fontFamily: "Lato", color: "var(--color-primary)" }} >Nombre Completo<span style={{ color: "red" }}>*</span></InputLabel>
                                            {/* <Input value={data.nombreCompleto} autoCapitalize='off' autoComplete='off' autoCorrect='off' margin='none' onChange={(e) => { setData({ ...data, nombreCompleto: e.target.value }) }} name="nombreCompleto" spellCheck={false} type={"email"} required={!test}
                                            inputProps={{ maxLength: 45, minLength: 10 }}>
                                        </Input> */}
                                            <Input  {...register("nombreCompleto", { required: !test })} required={!test} name={"nombreCompleto"} type={"text"} inputProps={{ maxLength: 75, minLength: 10, inputMode: "text", title: "Por favor ingrese su nombre completo." }}></Input>
                                            {/* {errors.nombreCompleto &&
                                                <span className="errorSpan">Campo requerido</span>} */}
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{ marginBottom: "20px" }} >
                                        <FormControl style={{ width: "100%" }}>
                                            <InputLabel variant="standard" style={{ marginTop: "10px", fontFamily: "Lato", color: "var(--color-primary)" }} >Fecha de nacimiento<span style={{ color: "red" }}>*</span></InputLabel>
                                            {/* <Input value={data.fechaNacimiento} autoCapitalize='off' autoComplete='off' error={errorDate} autoCorrect='off' margin='none'
                                            onChange={(e) => { setData({ ...data, fechaNacimiento: e.target.value }); changeDate(e) }} name="fechaNacimiento" spellCheck={false} type={"date"} required={!test}
                                            inputProps={{ max: minDate, min: "1920-01-01" }}>    onChange={(e) => { setFechaNac(e.target.value) }}
                                        </Input> */}
                                            <Input  {...register("fechaNacimiento", { required: !test, })} required={!test} value={fechaNac} onChange={(e) => { setFechaNac(e.target.value) }} inputProps={{ max: minDate, min: "1920-01-01", title: "Por favor ingrese su fecha de nacimiento." }} type="date" name={"fechaNacimiento"} ></Input>
                                            {/* {errors.fechaNacimiento &&
                                                <span className="errorSpan">Campo requerido</span>} */}
                                        </FormControl>


                                    </Grid>
                                    <Grid style={{ marginBottom: "20px" }} >
                                        <FormControl style={{ width: "100%" }}>
                                            <InputLabel variant="standard" style={{ marginTop: "10px", fontFamily: "Lato", color: "var(--color-primary)" }} >Correo Electrónico<span style={{ color: "red" }}>*</span></InputLabel>
                                            {/*<Input value={data.correo} autoCapitalize='off' autoComplete='off' autoCorrect='off' error={errorUser} margin='none' onChange={(e) => { setData({ ...data, correo: e.target.value }); changeUser(e) }} name="email" spellCheck={false} type={"email"} required={!test}
                                            inputProps={{ maxLength: 45, minLength: 10 }}>
                                        </Input> */}
                                            <Input  {...register("correo", { required: !test })} inputProps={{ pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$", inputMode: "email", title: "Por favor ingrese su correo electrónico." }} type="email" required={!test} name={"correo"}></Input>
                                            {/* {errors.correo &&
                                                <span className="errorSpan">Campo requerido</span>} */}
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginBottom: "20px", display: "flex", alignItems: "flex-end" }} >

                                        <FormControl style={{ width: "80px", height: "60px" }}>
                                            <InputLabel variant="standard" style={{ marginTop: "10px", fontFamily: "Lato, sans-serif", color: "var(--color-primary)" }} >Lada<span style={{ color: "red" }}>*</span></InputLabel>

                                            <Select style={{
                                                height: "100%",
                                                borderBottom: "1px solid rgba(0, 72, 170, 0.5)"
                                            }}  {...register("lada", { required: !test })} autoCapitalize='off' autoComplete='off' autoCorrect='off' margin='none' name="lada" spellCheck={false} required={!test}
                                                inputProps={{ defaultValue: 52, title: "Por favor ingrese su número de teléfono." }}
                                                variant="standard"
                                                renderValue={(value) => {
                                                    // console.log(value);
                                                    return (
                                                        <Grid item style={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
                                                            {value === 52 &&
                                                                <img style={{ margin: "auto", maxHeight: "15px", marginLeft: "0px" }} src='/static-files/flag-mexico.png' alt='Mexico Flag'></img>}
                                                            {value === 1 &&
                                                                <img style={{ margin: "auto", maxHeight: "15px", marginLeft: "0px" }} src='/static-files/flag-eua.png' alt='USA Flag'></img>}
                                                            +{value}
                                                        </Grid>
                                                    );
                                                }
                                                }

                                            >
                                                {/* <MenuItem value={52} style={{display: "flex", justifyContent: "space-between"}}><p>+52</p><img width={20} style={{margin: "0"}} src='/static-files/flag-mexico.png'></img></MenuItem>
                    <MenuItem value={1}>+1<img width={20} style={{margin: "0"}}src='/static-files/flag-eua.png'></img></MenuItem> */}
                                                <MenuItem value={52} style={{ display: "flex", justifyContent: "space-between" }}>+52</MenuItem>
                                                <MenuItem value={1}>+1</MenuItem>
                                                {/* onChange={changeUser} */}
                                            </Select>
                                        </FormControl>


                                        <FormControl style={{ width: "calc(100% - 80px)" }}>
                                            <InputLabel style={{ marginTop: "10px", fontFamily: "Lato", color: "var(--color-primary)" }} >Teléfono<span style={{ color: "red" }}>*</span></InputLabel>
                                            {/* <Input value={data.telefono} autoCapitalize='off' autoComplete='off' autoCorrect='off' margin='none'
                                            onChange={(e) => { setData({ ...data, telefono: e.target.value }) }} name="telefono" spellCheck={false} type={"tel"} required={!test}
                                            inputProps={{ maxLength: 45, minLength: 10 }}>
                                        </Input> */}
                                            <Input type={"tel"} {...register("telefono", { required: !test })} required={!test} name={"telefono"} inputProps={{ maxLength: 10, minLength: 10, inputMode: "tel", title: "Por favor ingrese su número de teléfono." }}></Input>
                                            {/* {errors.telefono &&
                                                <span className="errorSpan">Campo requerido</span>} */}
                                        </FormControl>
                                    </Grid>
                                    {/* <EmailAnimation /> */}
                                    {/* <Button className="btnLogin" style={{ alignSelf: "center" }} type="submit">
                                        Enviar
                                    </Button> */}

                                    <Button className="btnLogin" style={{ alignSelf: "center", marginTop: "25px" }} type="submit" value={"Enviar"} >Enviar </Button>
                                </form>
                            }



                        </Fragment>
                    }
                    {state === "code" &&
                        <Fragment>
                            <form onSubmit={handleSubmit(onSubmitCode)}>

                                <p style={{ fontWeight: "400", textAlign: "center" }}>
                                    {accion === "passeord" ? `Estimado inversionista en breve recibirá un correo electrónico a ${user}} con un token único necesario para generarle una nueva contraseña.`
                                        : `Estimado inversionista en breve recibirá un correo electrónico a ${user} con un token único necesario para enviar sus credenciales de acceso.`}
                                </p>
                                <Grid style={{ marginBottom: "20px" }} >
                                    <FormControl style={{ width: "100%" }}>
                                        <InputLabel style={{ marginTop: "10px", fontFamily: "Lato", color: "var(--color-primary)" }} >Token<span style={{ color: "red" }}>*</span></InputLabel>

                                        <Input {...register("token")} autoCapitalize='off' autoComplete='off' autoCorrect='off' margin='none'
                                            name="token" spellCheck={false} type={"text"} required={!test}
                                            inputProps={{ maxLength: 6, minLength: 6, inputMode: "text", title: "Por favor ingrese el Token que se le ha enviado a su correo electrónico." }}>

                                        </Input>

                                    </FormControl>
                                </Grid>
                                <Grid style={{ textAlign: "center" }}>
                                    <p>El token enviado caduca en 3 minutos.</p>
                                    <Button style={{
                                        color: "#0048aa",
                                        fontWeight: "bold",
                                        fontFamily: "Lato"
                                    }}
                                        type="button"
                                        onClick={handleSubmit(onSubmit)}
                                    >Solicitar nuevo token</Button>
                                </Grid>

                                <Grid style={{ height: "150px", alignSelf: "center", width: "240px", display: "flex", alignItems: "center" }} >
                                    <Grid className="divToken">
                                        <p className="tokenIcon">****** </p>
                                    </Grid>
                                </Grid>

                                <Button className="btnLogin" style={{ alignSelf: "center" }} type="submit" >
                                    Enviar
                                </Button>
                            </form>

                        </Fragment>
                    }
                    {state === "success" &&
                        <Fragment>
                            <p style={{ fontWeight: "400" }}>
                                Estimado inversionista en breve recibirá un correo electrónico a {user} con sus credenciales de acceso.
                            </p>
                            {/* <Grid style={{ marginBottom: "20px" }} >
                                <FormControl style={{ width: "100%" }}>
                                    <InputLabel style={{ marginTop: "10px", fontFamily: "Lato", color: "var(--color-primary)" }} >Token<span style={{ color: "red" }}>*</span></InputLabel>

                                    <Input value={token} autoCapitalize='off' autoComplete='off' autoCorrect='off' margin='none' onChange={(e) => {
                                        setToken(e.target.value)
                                    }} name="email" spellCheck={false} type={"email"} required={!test}
                                        inputProps={{ maxLength: 6, minLength: 6 }}>

                                    </Input>

                                </FormControl>
                            </Grid> */}


                            {/* <CheckCircleOutline htmlColor="#0048aa" style={{ width: "120px", height: "150px", alignSelf: "center" }} /> */}
                            <div className="check_mark">
                                <div className="sa-icon sa-success animate">
                                    <span className="sa-line sa-tip animateSuccessTip"></span>
                                    <span className="sa-line sa-long animateSuccessLong"></span>
                                    <div className="sa-placeholder"></div>
                                    <div className="sa-fix"></div>
                                </div>
                            </div>
                            <Button className="btnLogin" style={{ alignSelf: "center" }} onClick={() => { setOpen(false) }}>
                                Cerrar
                            </Button>
                        </Fragment>
                    }
                </Grid>
            </Grid>
        </Fragment>

    )
})

export default RecoveryPasswordActivateAccount;