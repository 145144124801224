import { Container, Grid } from "@mui/material";
import * as React from "react";
import FooterDireccion from "./direccionFooter/direccionFooter";
import facebook from "../images/svg/white/facebook.svg";
import { Link } from "gatsby";

const samplePageLinks = [
    // {
    //   text: "Acerca de Grupo Aries",
    //   url: "/acerca-grupo-aries",
    // },
    // {
    //   text: "Preguntas Frecuentes",
    //   url: "/faq",
    // },
    {
        text: "Aviso de Privacidad",
        url: "/aviso-privacidad/",
    },
    {
        text: "Política de Cookies",
        url: "/politicas-cookies",
    },
    {
      text: "Términos y condiciones",
      url: "/terminos-condiciones",
    },
]

type Props = {
    clickOnDirection: () => void
}

const Footer: React.FC<Props> = (props) => {
    return (
        <footer style={{ textAlign: "center", padding: 32}}>
            <Container maxWidth="xl">
                <Grid item className={"divFooter"}>
                    <Grid item xs={12} lg={9} className={"divtxtFooter"} style={{marginBottom: 9}} >
                        {samplePageLinks.map(link => (
                            <React.Fragment key={link.text}>
                                <Link className={"itemsFooter"} style={{ width: "fit-content", alignSelf: "center" }} to={link.url}>{link.text}</Link>
                            </React.Fragment>
                        ))}
                    </Grid>
                    <Grid item xs={12} lg={3} className={`${"divImgFooter"}`}>
                        <span className={""} style={{ textAlign: "right"}}>
                            <a href="https://www.facebook.com/GrupoAriesMX" target={"_blank"} rel={"noreferrer"} style={{ display: "flex", marginBottom: "0" }}>
                            <img className={"icon"} alt="Facebook" src={facebook} style={{/* minHeight: "20px", height: "20px",*/ marginBottom: "0", marginRight: "10px" }} />GrupoAriesMX
                            </a>
                        </span>
                    </Grid>
                </Grid>
                <FooterDireccion clickOnDirection={props.clickOnDirection}/>
                <p style={{ fontSize: 18 }}>Copyright {new Date().getFullYear().toString()} © Grupo Aries - Derechos reservados</p>
            </Container>
        </footer>
    )
}

export default Footer;
