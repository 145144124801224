import React, { useEffect, useRef } from 'react';
import { Wrapper } from "@googlemaps/react-wrapper";
import '../styles/servicio.css'

function MyMapComponent({
    center,
    zoom,
    onDirections = () => {},
    mapType = "satellite"
  }) {
    const ref = useRef();
  
    useEffect(() => {
        let map = new window.google.maps.Map(ref.current, {
            center,
            zoom,
            mapTypeId: mapType,
        });
        new window.google.maps.Marker({
            position: center,
            map,
            title: "Puntazul Residencial",
        });

        const locationButton = document.createElement('button');
        const puntazulInfo = document.createElement('div');

        puntazulInfo.textContent = 'Current Location';
        puntazulInfo.innerHTML = `<div id='staticMapInfo'><b style="margin-bottom: 20px !important">Puntazul Residencial</b><p>Autopista Tijuana Ensenada, km 36, 22713 Playas de Rosarito, B.C.</p></div>`;

        map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(puntazulInfo);

        locationButton.textContent = 'Current Location';
        locationButton.classList.add('custom-map-control-button');
        locationButton.innerHTML = `<div class="icon navigate-icon"></div><span>Cómo llegar</span>`;

        map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(locationButton);
        locationButton.addEventListener('click', () => {
            onDirections()
        });
    });
  
    return <div ref={ref} id="map" />;
}

export default function MapaDireccion({onCoordinatesLoaded = (coordinates)=>{}, mapType = "satellite"}) {
    const [coordinates, setCoordinates] = React.useState("");


    const center = { lat: 32.31792566165666, lng: -117.04578517495447 };
    const zoom = 17;

    function getCoordinates() {
        if(typeof navigator !== "undefined"){
            navigator.geolocation.getCurrentPosition(function (posicion) {
                let coor = posicion.coords.latitude + ',' + posicion.coords.longitude;
                setCoordinates(coor);
                onCoordinatesLoaded(coor);
            });
        }
        console.log("test coord");
    }

    return coordinates !== "" ? (
        <React.Fragment>
            <iframe title={"MapDireccionSinCoordenadas"} className={"mapServices"} src={`https://www.google.com/maps/embed/v1/directions?key=${process.env.GOOGLE_MAPS_API_KEY}&origin=${coordinates}&destination=Puntazul+Residecial&zoom=11&maptype=satellite`}></iframe>
        </React.Fragment>
    ) : (   
        <Wrapper apiKey={process.env.GOOGLE_MAPS_API_KEY}>
            <MyMapComponent center={center} zoom={zoom} onDirections={getCoordinates} mapType={mapType} />
        </Wrapper>
    )
}
