import { Box, Grid } from "@mui/material";
import * as React from "react";
import "./sheet-grid.css"
import useWindowSize from "../../hooks/useWindowSize";

type SheetGridProps = {
  children: any,
  disabled: boolean
}

const SheetGrid: React.FC<SheetGridProps> = ({children, disabled, ...gridProps}) => {
  const [isDragging, setIsDragging] = React.useState(false);
  const [position, setPosition] = React.useState({ y: 0 });
  const [transitionSpeed, setTransitionSpeed] = React.useState(0);
  const initialY = React.useRef(0);
  let windowSize = useWindowSize();

  const handleStart = (clientY: number) => {
    setIsDragging(true);
    initialY.current = clientY;
    setTransitionSpeed(0);
  };

  const handleMove = (clientY: number) => {
    if (!isDragging) return;
    const deltaY = clientY - initialY.current;
    setPosition({ y: deltaY });
  };

  const handleEnd = () => {
    setIsDragging(false);
    setPosition({ y: 0 });
    setTransitionSpeed(0.3);
    setTimeout(() => {
      setTransitionSpeed(0);
    }, 300);
  };

  const handleMouseDown = (e: React.MouseEvent) => handleStart(e.clientY);
  const handleMouseMove = (e: React.MouseEvent) => handleMove(e.clientY);
  const handleMouseUp = () => handleEnd();
  const handleTouchStart = (e: React.TouchEvent) => handleStart(e.touches[0].clientY);
  const handleTouchMove = (e: React.TouchEvent) => handleMove(e.touches[0].clientY);
  const handleTouchEnd = () => handleEnd();

  return (
    (windowSize?.width < 768 && windowSize?.height > 530) ?
      <Grid {...gridProps} style={{transform: `translateY(${Math.max(0, position.y)}px)`, transition: transitionSpeed + "s"}}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        >
        <Box className="dragHandler">
        </Box>
        {children}
      </Grid>
    :
      <Grid {...gridProps}
        >
        {children}
      </Grid>
  )
}

export default SheetGrid;
